import React, { useState, useEffect } from "react";
import "./AdminOrders.css";
import { CLIENT_API, DEPARTMENT_API, USER_API } from "../../constants/api";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { format } from 'date-fns';
const StyledCell = styled.div`
  &.low {
    background: linear-gradient(to right, #1f2123, #0f64a2) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.medium {
    background: linear-gradient(to right, #1f2123, #5b2b99) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.high {
    background: linear-gradient(to right, #1f2123, #d09c17) !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

function AdminOrders() {
  const userId = localStorage.getItem("userID")
  const role = localStorage.getItem("role")
  const token = localStorage.getItem("token")
  const d_Id = localStorage.getItem("departmentId") 
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [records, setRecords] = useState([]);
  function getCssClass(value) {
    if (value === "Essential" || value === "E-commerce") return "low";
    else if (value === "Premium") return "medium";
    return "high";
  } 
  const columns = [
  

    {
      name: "Subscription",
      selector: (row) => (
        <>
          {row.projectCategory.name}
          {row.packageName === "Essential" || row.packageName === "E-commerce" ? (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #0f64a2)" }}
              className="ms-1"
            >
              {row.packageName}
            </Badge>
          ) : (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #d09c17)" }}
              className="ms-2"
            >
              {row.packageName}
            </Badge>
          )}
          
        </>
      ),
      sortable: true,
      // width: "fit-content", 
      minWidth: "270px",
      grow: 2,
    },
        { 
      name: "Name", 
      selector: (row) => {
       return users &&
        users.find(user => user._id === row.userId).username
      },
      sortable: true,
    },
    // {
    //   name: "Title",
    //   selector: (row) => row.projectTitle,
    //   sortable: true,
    // },
    {
      name: "Amount",
      selector: (row) => row.currency.toUpperCase() +" "+row.price,
      sortable: true,
      // width: "fit-content", 
      
      minWidth: "150px", 
    },
 
    {
      name: "Purchase Date",
      selector: (row) => {
        const createdAtDate = new Date(row.createdAt);
        const day = createdAtDate.getDate();
        const monthIndex = createdAtDate.getMonth();
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November", 
          "December",
        ];
        const month = months[monthIndex];
        const year = createdAtDate.getFullYear();
        const formattedDate = `${day} ${month}, ${year}`;
        return formattedDate;
      },
      sortable: true,
      minWidth: "150px", 
      // width: "fit-content", 
    },
    {
      name: "Expiry Date",
      selector: (row) => {
        const expiryDate = new Date(row.expiryDate);
        const currentDate = new Date();
    
        return expiryDate < currentDate ? 
          <span className="bg-danger p-2 text-white" style={{borderRadius: "30px"}}>
            {format(expiryDate, 'dd MMM, yyyy')}
          </span> : 
          format(expiryDate, 'dd MMM, yyyy');
      },
      sortable: true,
      minWidth: "150px",
    },
  
    // {
    //   name: "Status",
    //   selector: (row) => "In Progress",
    //   sortable: true,
    // },
    // {
    //   name: "Package",
    //   selector: (row) => row.packageName,
    //   sortable: true,
    //   cell: (row) => (
        
        //   row.packageName === "Essential" || row.packageName === "E-commerce"?

        //   <Badge style={{background: "linear-gradient(to right, #1f2123, #0f64a2)"}} className="ms-3">
        //   {row.packageName} 
        //  </Badge>
        //   :
        //   <Badge style={{background: "linear-gradient(to right, #1f2123, #d09c17)"}} className="ms-3">
        //  {row.packageName}
        // </Badge>
        
       
    //   ),
    // },
    {
      name: "View Briefs",
      selector: (row) => ( 
        <Link to={`/auth/admin-order-page/${row._id}`} className="btn btn-sm" style={{color: "white", backgroundColor: "black"}}>View Briefs</Link> 
      ),
      minWidth: "150px", 
      // minWidth: "100px",
    }, 
   
  ];




  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(USER_API + "/users/list")
      const json = await response.json()
      if(response.ok){
        setUsers(json.data)
      }
    } 
    const getProjects = async () => {

      try{
        setLoading(true)
        const response = await fetch(CLIENT_API + "/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await response.json();
        if (response.ok) {
          if (json.data == null) {
            setRecords([]);
          } else {
            const formattedData = json.data.map((record) => {
              const createdAtDate = new Date(record.createdAt);
              const day = createdAtDate.getDate();
              const monthIndex = createdAtDate.getMonth();
              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              const month = months[monthIndex];
              const year = createdAtDate.getFullYear();
              const formattedDate = `${day} ${month}, ${year}`;
              return { ...record, formattedDate };
            });
            setProjects(formattedData);
            setRecords(formattedData);
          
          }
        } else {
          setRecords([]);
        }
      }catch(error){

      }finally{
        setLoading(false)
      }
 
   
    
  };
    fetchUsers();
    getProjects(); 
  }, [userId]);
  const setFilter = (value) => {
    const result = projects.filter((record) => {
      return (
        record.currency.toLowerCase().includes(value.toLowerCase()) ||
        record.price.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.projectCategory.name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        record.packageName.toLowerCase().includes(value.toLowerCase()) ||
        record.formattedDate
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) 
      );
    });

    setRecords(result);
  };

  const tableHeaderStyle = {
    headRow: {
      style: {
        backgroundColor: "#1F2123",
      },
    },
    headCells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        fontSize: "14px",
      },
    },
    rows: { 
      style: {
        backgroundColor: "#1F2123",
        color: "#ffffff",
        
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#1F2123",
        padding: "20px",
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    paginationButton: {
      style: {
        fontFamily: "HelveticaNeueLTStd",
        color: "#828282",
        fontSize: "14px",
      },
    },
    table: {
      style: {
        padding: "10px",
        backgroundColor: "#1F2123",
      },
    },
  };

  return (
    <>
 <div className="container-fluid">
  <h2 className="mb-5">All Orders</h2>
 
  <div className="header-body">
    <div className="row mb-1 d-flex justify-content-end">
      <div className="col-md-4 col-sm-6">
        <form action="">
        <input
              className={`form-control ${
                projects == null ? "d-none" : ""
              } `}
              placeholder="Search"
              onChange={(e) => setFilter(e.target.value)}
              type="text"
            />
        </form>
   
      </div>
 
    </div>
    <div className="row">
    
    <DataTable
        columns={columns} 
        data={records}
        pagination
        progressPending={loading}
        progressComponent={
          <div className="text-center my-2 bg-white">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
        // Add custom styles or classes here using Bootstrap classes
        className="table table-striped table-hover"
      />

    </div>
  </div>
</div>
    </>
  );
}

export default AdminOrders;
