import React from 'react'
import SectionOneMobile from './sectionOneMobile/SectionOneMobile'
import SectionTwoMobile from './sectionTwoMobile/SectionTwoMobile'
import SectionThreeMobile from './sectionThreeMobile/SectionThreeMobile'
import SectionFourMobile from './sectionFourMobile/SectionFourMobile'
import SectionFiveMobile from './sectionFiveMobile/SectionFiveMobile'
import SectionSixMobile from './sectionSixMobile/SectionSixMobile'
import SectionSevenMobile from './sectionSevenMobile/SectionSevenMobile'
import SectionEightMobile from './sectionEightMobile/SectionEightMobile'

function MobileComponents() {
  return (
    <div>
        <SectionOneMobile/>
        <SectionTwoMobile/>
        <SectionThreeMobile/>
        <SectionFourMobile/>
        <SectionFiveMobile/>
        <SectionSixMobile/>
        <SectionSevenMobile/>
        <SectionEightMobile/>
    </div>
  )
}

export default MobileComponents