import React, { useState } from "react";
import "./SectionThreeMobile.css";

function SectionThreeMobile() {

  const [activeProject, setActiveProject] = useState("Branding")

  return (
    <div id="section-three-mobile" className="section-three-mobile d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center fs-1 fw-bold section-three-heading">
              Featured Projects 
            </h1>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-12">
          <button

          onClick={()=>setActiveProject("Branding")}
              className={`section-three-tabs ${activeProject === "Branding"? "active-project": ""}`}
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching"
              data-bs-slide-to="0"
            >
              Branding
            </button>
          <button
          onClick={()=>setActiveProject("Social Media")}
          className={`section-three-tabs ${activeProject === "Social Media"? "active-project": ""}`}
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching"
              data-bs-slide-to="1"
            >
              Social Media
            </button>
          <button
           onClick={()=>setActiveProject("UI/UX")}
           className={`section-three-tabs ${activeProject === "UI/UX"? "active-project": ""}`}
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching"
               data-bs-slide-to="2"
            >
              UI/UX
            </button>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center">
          <div className="col-md-12">
            <div
              id="carouselExampleControlsNoTouching"
              className="carousel slide"
              data-bs-touch="false"
              data-bs-interval="false"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4 sec-three-1st-col p-2">
                      <img
                        className="section-three-image"
                        src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718293302/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/bqwulqlgmm7raguspnho.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12 sec-three-2nd-col p-2">
                          <img
                            className="section-three-image"
                            src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718295754/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/mjde881vdnrrletapyat.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-md-12 sec-three-3rd-col p-2">
                          <img
                            className="section-three-image"
                            src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718296361/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/adh48y9qjvil2zn3f23u.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 sec-three-4rth-col p-2">
                      <img
                        className="section-three-image"
                        src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718383592/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/fr90cbpadj6ovqitsndq.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4 sec-three-1st-col p-2">
                      <img
                        className="section-three-image"
                        src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719839660/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/on2nhrffbiqlkpf91tc1.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12 sec-three-2nd-col p-2">
                          <img
                            className="section-three-image"
                            src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718385821/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/vuqcafpooueimudf4udf.png"
                            alt=""
                          />
                        </div>
                        <div className="col-md-12 sec-three-3rd-col p-2">
                          <img
                            className="section-three-image"
                          src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719838924/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/lsjocxybxxcifn43gdvv.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 sec-three-4rth-col p-2">
                      <img
                        className="section-three-image"
                        src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718385495/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/he9y9mzyuzhhuqvjmcrd.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4 sec-three-1st-col p-2">
                      <img
                        className="section-three-image"
                        src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719841242/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/t00omlfsaboxqmpmunl8.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12 sec-three-2nd-col p-2">
                          <img
                            className="section-three-image"
                            src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719840862/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/jas20i0h2qpprnihhguf.png"
                            alt=""
                          />
                        </div>
                        <div className="col-md-12 sec-three-3rd-col p-2">
                          <img
                            className="section-three-image"
                            src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1718386392/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/i7ed8qlxjbsyjzwtakts.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 sec-three-4rth-col p-2">
                      <img
                        className="section-three-image"
                        src="https://res.cloudinary.com/dyr0imjlo/image/upload/v1719840411/ADD_HYPE_SUBSCRIPTION/MEDIA_IMAGES/w7q1cuufs1ow2pz8odvc.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionThreeMobile;
