import React, { useState, useRef, useEffect } from "react";
import "./ProjectBrief.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  CLIENT_API,
  USER_API,
  MESSAGE_API,
  DEPARTMENT_API,
  STRIPE_API,
  CATEGORY_API,
  CONFIGURE_API,
  BRIEF_API,
} from "../../../../src/constants/api";

function  ProjectBrief() {
 
  const navigate = useNavigate();

  const packageName = localStorage.getItem("selectedPackage");
  const packagePrice = localStorage.getItem("selectedPackagePrice");
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const token = localStorage.getItem("token");
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const projectCategory = localStorage.getItem("selectedPackageCategory");
  const [categoryName, setCategoryName] = useState("")
  const packageId = localStorage.getItem("packageId")
  const [services, setServices] = useState([])
  const [newProjectId, setNewProjectId] = useState("")

  const [loading, setLoading] = useState(false);
 
  const [file, setFile] = useState("");

const getStripeData = async (userID) => {


  if(!userID || userID === null){
 
  }else{
    const response = await fetch(STRIPE_API+"/current/client/"+userID)

    const jsonData = await response.json()

    if(response.ok){
     await setUserEmail(jsonData.data.email);
     await setUserId(jsonData.data.id);
     await setPrice(jsonData.data.price);
     await setCurrency(jsonData.data.currency);
     localStorage.setItem("token", jsonData.token);
     localStorage.setItem("userId", jsonData.data.id);
     localStorage.setItem("role", jsonData.data.role);
  
    }
  }

}

useEffect(()=>{

  const submitProject = async () =>{
    if(userEmail.trim() !== ""){

      try{
     
        const formData = new FormData();

        formData.append("userId", userId);
        formData.append("projectCategory", projectCategory);
        formData.append("price", price);
        formData.append("currency", currency);
        formData.append("packageName", packageName);
        formData.append("package", packageId)

        const response = await fetch(CLIENT_API+"/create",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          
        })
        const json = await response.json();

        if (!response.ok) {
      
          toast.error(json.message);
        }
        if (response.ok) {
      
          setNewProjectId(json.project._id)
          toast.success("Payment Successful!!!");
        }


      }catch(error){

      }finally{

      }
    }
  }

  submitProject()
  
},[userEmail])

useEffect(()=>{

  const fetchPackageCategoryName = async () => {

    if(projectCategory.trim() !== ""){
      const response = await fetch(`${CATEGORY_API}/${projectCategory}`) 
      const json = await response.json()

      if(response.ok){
        setCategoryName(json.data.name)
      }
    }
    
  }

  const fetchPackageDetails = async () => {
    try{

      const response = await fetch(`${CONFIGURE_API}/includes/list/${packageId}`)
      const json = await response.json()
      if(response.ok){

   
        const options = json.data.map((service)=>(
          {
            value: service._id,
            label: service.title
          }
        ))

        setServices(options)
      }

    }catch(error){
      toast.error(error);
    }
  }


  fetchPackageCategoryName()
  fetchPackageDetails()

}, [projectCategory])

  useEffect(() => {
   

    const getClientDetails = async () => {

      const response = await fetch(STRIPE_API+"/client/details")
      const jsonData = await response.json()

      if(response.ok){
        
        getStripeData(jsonData.data.userId)
      }
  }

    getClientDetails()

  }, []);

  const handleImageChange = (e) => {
    setFile(e.target.files[0]);

  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if(newProjectId.trim() !== ""){
    
      setLoading(true);
      const loadingToastId = toast.loading("Submitting Project Details...");
  
      const formData = new FormData();
  
      formData.append("createdBy", userId);
      formData.append("title", projectTitle);
      formData.append("description", projectDescription);
      formData.append("clientProjectId", newProjectId);
  
      if (file) {
        formData.append("file", file);
      }
      try {
        const response = await fetch(BRIEF_API + "/create", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const json = await response.json();
  
        if (!response.ok) {
          toast.dismiss(loadingToastId);
          toast.error(json.message);
        }
        if (response.ok) {
          toast.dismiss(loadingToastId);
       
          toast.success("Brief submitted successfully!");
          navigate(`/client-auth/dashboard`)
  
        }
      } catch (error) {
        console.error("Error submitting Brief:", error);
        toast.dismiss(loadingToastId);
        toast.error("An error occurred while submitting.");
      } finally {
        setLoading(false);
      }
    }

  };

  return (

    <div className="container-fluid order-checkout">
      <div className="row d-flex justify-content-center">
        {/* <div className="col-md-3"></div> */}
        <div className="col-lg-9 col-md-10 col-sm-12">
        <div className="container-fluid">
    <h2 className="mb-5 text-center">Submit your Project Details</h2>
    <div className="header-body">
      <div className="card">
        <div className="card-body">
          <form className="row g-3">
            <div className="col-md-4">
              <label htmlFor="inputCity" className="form-label">
                Email
              </label>
              <input
                type="text"
                value={userEmail}
                className="form-control"
                id="inputCity"
                disabled
                readonly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputState" className="form-label">
                Category
              </label>
              <input
                type="text"
                value={categoryName}
                className="form-control"
                id="inputCity"
                disabled
                readonly
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputZip" className="form-label">
                Package
              </label>
              <input
                type="text"
                className="form-control"
                id="inputZip"
                disabled
                readonly
                value={`${packageName} ( ${packagePrice} )`}
              />
            </div>
            <div className="col-md-8">
              <label htmlFor="inputEmail4" className="form-label">
                Choose Service
              </label>
             <Select
             options={services}
             onChange={(e)=>setProjectTitle(e.value)}
           
             />
            </div>
            <div className="col-md-4">
              <div>
                <label htmlFor="formFile" className="form-label">
                  Attach File (Optional)
                </label>
                <input
                  onChange={handleImageChange}
                  className="form-control"
                  type="file"
                  id="formFile"
                     accept="image/*,.pdf"
                />
              </div>
            </div>

            <div className="col-md-12">
              <label htmlFor="inputEmail4" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                onChange={(e) => setProjectDescription(e.target.value)}
                id="exampleFormControlTextarea1"
                rows={10}
              
              />
            </div>

            <div className="col-12">
              <button
              onClick={handleSubmit}
                type="submit"
                className="btn btn-dark float-right"
                style={{ color: "white", background: "black" }}
              >
                Submit
              </button>
              <Link
                to="/client-auth/dashboard"
                type="submit"
                className="btn btn-outline-dark"
              >
                Skip to dashboard
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
        </div>
      </div>
    </div>
  
  );
}

export default ProjectBrief;
