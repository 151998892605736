import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import { Container } from "reactstrap";

const UpdateNotification = createContext()

const AdminFullLayout = () => {

  const [update, setUpdate] = useState("0");
  return (
    <UpdateNotification.Provider value={{update, setUpdate}}>
    <main>
      {/********header**********/}
      <AdminHeader />
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        <aside className="sidebarArea shadow" id="sidebarArea">
          <AdminSidebar />
        </aside>
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="p-4" fluid>
          
            <Outlet />
           
          
          </Container>
        </div>
      </div>
    </main>
    </UpdateNotification.Provider>
  );
};

export default AdminFullLayout;
export {UpdateNotification};
