import React, { useState } from "react";
import { useParams } from "react-router-dom";

import './ClientBriefPage.css'
import Activity from "../../components/singleordercomponents/Activity";
import Details from "../../components/singleordercomponents/Details";

function ClientBriefPage() {
    const [toggle, setToggle] = useState("Activity")
  const { projectId, briefId } = useParams();
  return (
    <>
    <div className="container-fluid">
        <h2 className="mb-5">Brief Details</h2>
        {/* <div className="header-body">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <button
                    onClick={() => setToggle("Activity")}
                    className={`nav-link ${
                      toggle === "Activity" ? "active" : ""
                    }`}
                    aria-current="page"
                  >
                    Activity
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={() => setToggle("Details")}
                    className={`nav-link ${
                      toggle === "Details" ? "active" : ""
                    }`}
                  >
                    Details
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <div className="header-body mt-5">
          <div className="row">
            {/* {toggle === "Activity" ? <Activity /> : <Details />} */}
            <Activity/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientBriefPage;
