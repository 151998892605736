import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  TextField,
  Button,
  TablePagination,
} from "@mui/material";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FAQ_API } from "../../../constants/api";
import "./Faqs.css";
import Delete from "@mui/icons-material/Delete";

function Faqs() {
  const [open, setOpen] = useState({});
  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [upadte, setUpdate] = useState("");
  const token = localStorage.getItem("token");

  const handleClick = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
  };

  useEffect(() => {
    const getFaqs = async () => {
      const response = await fetch(FAQ_API + "/list");
      const faq = await response.json();

      if (response.ok) {
        setFaqs(faq.data);
        setFilteredFaqs(faq.data);
      }
    };

    getFaqs();
  }, [upadte]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearch(query);
    const filtered = faqs.filter((faq) =>
      faq.question.toLowerCase().includes(query)
    );
    setFilteredFaqs(filtered);
    setPage(0); // Reset page to 0 when searching
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Adding FAQ...");
    const faq = { question, answer };

    try {
      const response = await fetch(FAQ_API + "/create", {
        method: "POST",
        body: JSON.stringify(faq),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("FAQ Added successfully!");
        setUpdate(Math.random());
        setQuestion("");
        setAnswer("");
      }
    } catch (error) {
      console.error("Error Adding FAQ:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while adding FAQ.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  const handleDelete = (myId) => {
    console.log(myId);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-danger btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false, 
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteUser = async (d_id) => {
            const response = await fetch(FAQ_API + "/delete/" + d_id, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });
            if(response.ok){
                setUpdate(Math.random());
            }
            
            setUpdate(d_id);
          };
          deleteUser(myId);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "User is safe :)",
            icon: "error",
          });
        }
      });
  };

  return (


<div className="container-fluid">
<h2 className="mb-5">FAQs</h2>

<div className="header-body">

<div className="row">
        <div className="col-md-12">
          <form className="row g-3">
            <div className="col-md-12">
              <label className="form-label">Question</label>
              <input
                type="text"
                className="form-control"
                placeholder="Why wouldn't I just hire a full-time designer?"
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>

            <div className="col-md-12">
              <label className="form-label">Answer</label>
              <textarea
                className="form-control"
                placeholder="Write your answer here..."
                rows={6}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <button className="btn float-right"
              style={{backgroundColor: "black", color: "white"}}
              onClick={handleSubmit}>
                Add New FAQ
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-12">
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearch}
            margin="normal"
            style={{ backgroundColor: "#ffffff", fontFamily: "HelveticaNeueLTStd" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#828282", // Text color
                "& fieldset": {
                  borderColor: "#dee2e6", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#dee2e6", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#dee2e6", // Border color when focused
                },
              },
              "& .MuiInputLabel-root": {
                color: "#828282", // Text color
                fontFamily: "HelveticaNeueLTStd",
                "&.Mui-focused": {
                  color: "#828282", // Label color when focused
                },
              },
            }}
          />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableBody>
                {filteredFaqs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((faq) => (
                    <React.Fragment key={faq._id}>
                      <TableRow style={{ backgroundColor: "#1D1F20" }}>
                        <TableCell style={{ width: "50px" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleClick(faq._id)}
                            style={{ color: "#828282" }}
                          >
                            {open[faq._id] ? (
                              <>
                                <KeyboardArrowUpIcon />
                              </>
                            ) : (
                              <>
                                <KeyboardArrowDownIcon />
                              </>
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#828282",
                            fontSize: "16px",
                            fontFamily: "HelveticaNeueLTStd",
                          }}
                        >
                          {faq.question}
                        </TableCell>
                        <TableCell> 
                          <Delete onClick={() => handleDelete(faq._id)} style={{ float: "right", color: "#E67373", fontSize: "20px", cursor: "pointer" }} />
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: "#1D1F20" }}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={open[faq._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Typography
                              variant="body2"
                              style={{
                                padding: "16px",
                                color: "#828282",
                                fontSize: "16px",
                                fontFamily: "HelveticaNeueLTStd",
                              }}
                            >
                              {faq.answer}
                            </Typography>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            // style={{ color: "white", fontFamily: "HelveticaNeueLTStd" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredFaqs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
</div>
</div>
  );
}

export default Faqs;
