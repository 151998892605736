import { React, useState, useContext, useRef, useEffect } from "react";
import "./PortfolioFGallery.css";
import { toast } from "react-toastify";
import { pImage } from "../../pages/portfolio/newportfolio/AddNewPortfolio";
import { MEDIA_API } from "../../constants/api";

function PortfolioFGallery(props) {
  const [selectedImageId, setSelectedImageId] = useState("");
  const inputRef = useRef("");
  const { featuredImageId, setFeaturedImageId } = useContext(pImage);
  const [images, setImages] = useState(null);
  const [file, setFile] = useState("");

  useEffect(() => {
    const getMedia = async () => {
      try {
        const response = await fetch(MEDIA_API+"/list");

        if (response.ok) {
          const json = await response.json();
          setImages(json.data);
        } else {
        }
      } catch (error) {
      } finally {
      }
    };
 
    getMedia();
  }, [selectedImageId]);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleUpload = async (myFile) => {
    const loadingToast = toast.loading("Uploading Image...");

    try {
      const formData = new FormData();
      formData.append("image", myFile);

      const response = await fetch(MEDIA_API+"/upload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          setSelectedImageId(responseData.data._id);
          toast.dismiss(loadingToast);
          toast.success("Media uploaded successfully!");
        } else {
          toast.dismiss(loadingToast);
          toast.error("Failed to upload media.");
        }
      } else {
        toast.dismiss(loadingToast);
        toast.error("Server error.");
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Error uploading media:", error);
      toast.error("Error uploading media.");
    } finally {
    }
  };

  const handleImageChange = (e) => {
    const myFile = e.target.files[0];
    setFile(e.target.files[0]);

    handleUpload(myFile);
  };

  return (
    <div
      className="modal fade bd-example-modal-lg"
      id="exampleModalCenter"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "90vw", width: "90vw" }}
      >
        <div
          className="modal-content"
          style={{ height: "90vh", borderRadius: 0 }}
        >
          <div className="modal-header">
            <p className="modal-title" id="exampleModalLongTitle">
              {props.name}
            </p>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setSelectedImageId("")}
                className="text-white"
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body bg-light">
            <div
              className="container-fluid p-0"
              style={{ height: "100%", width: "100%" }}
            >
              <div className="row" style={{ height: "100%", width: "100%" }}>
                <div
                  className="col-md-9 bg-white"
                  style={{ height: "68vh", overflowY: "auto" }}
                >
                  <div className="row">
                    {images &&
                      images.map((image) => (
                        <div
                          key={image._id}
                          className={`col-md-3 p-1 d-flex view-media justify-content-center ${
                            selectedImageId == image._id ? "image-clicked" : ""
                          }`}
                        >
                          <img
                            src={image.secure_url}
                            alt=""
                            className="img-fluid media-image"
                            onClick={() => setSelectedImageId(image._id)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-md-3 d-flex view-media justify-content-center">
                  {images &&
                    images.map((image) =>
                      image._id == selectedImageId ? (
                        <img
                          src={image.secure_url}
                          alt=""
                          className="img-fluid"
                        />
                      ) : null
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <input
              type="file"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <button
              type="button"
              className="btn addhype-button-2"
              onClick={handleImageClick}
            >
              Upload Image
            </button>
            <button
              type="button"
              className="btn addhype-button"
              data-dismiss="modal"
              onClick={() => setFeaturedImageId(selectedImageId)}
            >
              Set Featured Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioFGallery;
