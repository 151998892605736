import React, { useState, useEffect } from "react";
import "./Login.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { USER_API } from "../constants/api";
import { useGoogleLogin } from "@react-oauth/google";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse?.access_token;

      console.log(tokenResponse);
      if (accessToken) {
        localStorage.setItem("access-token", accessToken);

        toast.success("Logged In successfully!");
        navigate("/auth/dashboard");
      }
    },
    onError: (error) => {
      console.error(error);
    },
    scope: "https://www.googleapis.com/auth/analytics.readonly", // Ensure proper scope is set
  });
  useEffect(() => {
    const check_token = localStorage.getItem("token");

    if (check_token) {
      navigate("/auth/dashboard");
    }
  }, [navigate]);
  // useEffect(() => {
  //   if (googleLogin.tokenResponse?.access_token) {
  //     fetchData(googleLogin.tokenResponse.access_token);
  //   }
  // }, [googleLogin.tokenResponse]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Logging in...");
    const user = { email, password };

    console.log(USER_API);
    try {
      const response = await fetch(USER_API + "/login", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        // if(json.user.role ==! "client"){
        if (json.user.role === "super-admin" || json.user.role === "admin" ) {
          toast.dismiss(loadingToastId);
          toast.success("Logged In successfully!");
          localStorage.setItem("token", json.token);
          localStorage.setItem("userID", json.user.id);
          localStorage.setItem("role", json.user.role);
          localStorage.setItem("departmentId", json.user.department);
          navigate("/auth/dashboard");

          // googleLogin()
        } else if (json.user.role === "lead") {
          toast.dismiss(loadingToastId);
          toast.success("Logged In successfully!");
          localStorage.setItem("token", json.token);
          localStorage.setItem("userID", json.user.id);
          localStorage.setItem("role", json.user.role);
          localStorage.setItem("departmentId", json.user.department);
          navigate("/auth/dashboard");
        } else {
          toast.error("User does Not exist.");
          toast.dismiss(loadingToastId);
        }
      }
    } catch (error) {
      console.error("Error login admin:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while Login admin.");
    } finally {
      setLoading(false);
    }
  };
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="admin-login-page d-flex flex-column">
     <div className="align-self-start mx-auto mt-5">
        <img
          src="/media/hypeX-logo-dark.svg"
          role="button"
          onClick={handleClick}
          className="img-fluid"
          alt="profile"
        />
      </div>
      <div className="container align-self-center my-auto">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h2 className="text-center text-dark">HypeX Admin</h2>
            <div className="text-center text-dark">Login to Dashboard</div>
            <div className="my-5">
              <form className="card-body cardbody-color p-lg-5"
               style={{ backgroundColor: "transparent" }}>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    id="Username"
                    aria-describedby="emailHelp"
                    placeholder="User Name"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    placeholder="password"
                  />
                </div>
                <div className="text-center">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn admin-login-btn-color px-5 mb-1 w-100"
                  >
                    Login
                  </button>
                </div>
                <div className="text-center">
                  <Link to="/" className="px-5 mb-5 w-100 text-dark">
                    Back to Home
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
