import React from "react";
import { useState, useRef, createContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./AddNewService.css";
import JoditEditor from "jodit-react";
import FeaturedMediaGallery from "../../../components/featuredmediaGallery/FeaturedMediaGallery";
import MediaGallery from "../../../components/mediaGallery/MediaGallery";
import { MEDIA_API, SERVICE_API } from "../../../constants/api";

const pImage = createContext();
const gImage = createContext();

function AddNewService() {
  const editor = useRef(null);
  const [serviceName, setServiceName] = useState("");
  const [allImages, setAllImages] = useState(null);
  const [content, setContent] = useState("");
  const [featuredImageId, setFeaturedImageId] = useState("");
  const [featuredImage, setFeaturedImage] = useState([]);
  const [gImageId, setgImageId] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [galleryLoading, setGalleryLoading] = useState(false);

  const [packages, setPackages] = useState([
    {
      name: "",
      price: "",
      duration: "",
      url: "",
    },
  ]);

  useEffect(() => {
    const getFeaturedImage = async () => {
      console.log(featuredImageId);
      if (featuredImageId !== "") {
        const response = await fetch(MEDIA_API+"/list");
        const json = await response.json();

        if (response.ok) {
          const myfeaturedImage = json.data
            .filter((image) => featuredImageId.includes(image._id))
            .map(({ public_id, secure_url }) => ({ public_id, secure_url }));
          setFeaturedImage(myfeaturedImage);
        }
      }
    };

    getFeaturedImage();
  }, [featuredImageId]);

  const filterGalleryImages = (allData) => {
    const myGalleryImages = allData
      .filter((image) => gImageId.includes(image._id))
      .map(({ _id, public_id, secure_url }) => ({
        _id,
        public_id,
        secure_url,
      }));
    setGalleryImages(myGalleryImages);
  };

  useEffect(() => {
    setGalleryLoading(true);

    const getgImage = async () => {
      try {
        console.log(gImageId);
        if (gImageId.length > 0) {
          const response = await fetch(MEDIA_API+"/list");
          const json = await response.json();

          if (response.ok) {
            setAllImages(json.data);
            filterGalleryImages(json.data);
            setGalleryLoading(false);
          }
        }
      } catch (error) {
      } finally {
        setGalleryLoading(false);
      }
    };

    getgImage();
  }, [gImageId]);

  let handleChange = (i, e) => {
    let newFormValues = [...packages];
    newFormValues[i][e.target.name] = e.target.value;
    setPackages(newFormValues);
  };

  let addFormFields = () => {
    let latestPackages = [...packages];
    let string1 = latestPackages[latestPackages.length - 1]["name"];
    let string2 = latestPackages[latestPackages.length - 1]["price"];
    let string3 = latestPackages[latestPackages.length - 1]["duration"];

    if (
      string1.trim().length === 0 ||
      string2.trim().length === 0 ||
      string3.trim().length === 0
    ) {
      toast.error("Please make sure name, price and duration is filled");
    } else {
      setPackages([...packages, { name: "", price: "", duration: "" }]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...packages];
    newFormValues.splice(i, 1);
    setPackages(newFormValues);
  };

  const handleRemoveFeaturedImage = () => {
    setFeaturedImageId("");
    setFeaturedImage([]);
  };

  const removeGalleryImage = (imageId, i) => {
    const index = gImageId.indexOf(imageId);

    let newImageId = [...gImageId];
    newImageId.splice(index, 1);
    setgImageId(newImageId);

    if (newImageId.length < 1) {
      let newGalleryImage = [...galleryImages];
      newGalleryImage.splice(i, 1);
      setGalleryImages(newGalleryImage);
    }
  };

  const handlePublish = async () => {
    setLoading(true);
    const fImage = featuredImage;
    const url = "";
    const title = serviceName;
    const description = content;
    const loadingToastId = toast.loading("Publishing Service...");

    const gImages = galleryImages.map((image) => {
      const { _id, ...rest } = image;
      return rest;
    });

    const service = { title, description, packages, url, fImage, gImages };

    try {
      const response = await fetch(SERVICE_API+"/create", {
        method: "POST",
        body: JSON.stringify(service),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("Service published successfully!");
        setServiceName("");
        setContent("");
      }
    } catch (error) {
      console.error("Error publishing service:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while publishing service.");
    } finally {
      setLoading(false);
      console.log(serviceName);
      console.log(content);
      console.log(packages);
      console.log(featuredImage);
    }
  };
  return (
    <div className="container-fluid">
    <div className="row">
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-light">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Service Name</label>
                  <input
                    type="text"
                    placeholder="Enter Service Name"
                    onChange={(e) => setServiceName(e.target.value)}
                    className="form-control"
                    id="inputEmail4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-light">
              <div className="card-body">
                <label htmlFor="inputEmail4">Packages</label>
                {packages.map((element, index) => (
                  <div className="form-row align-items-center">
                    <div className="col-sm-4 my-1">
                      <input
                        type="text"
                        value={element.name || ""}
                        onChange={(e) => handleChange(index, e)}
                        className="form-control"
                        name="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-sm-2 my-1">
                      <div className="input-group">
                        <input
                          type="text"
                          value={element.price || ""}
                          onChange={(e) => handleChange(index, e)}
                          className="form-control"
                          name="price"
                          placeholder="$ Price"
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 my-1">
                      <div className="input-group">
                        <input
                          type="text"
                          value={element.url || ""}
                          onChange={(e) => handleChange(index, e)}
                          className="form-control"
                          name="url"
                          placeholder="URL"
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 my-1">
                      <div className="input-group">
                        <input
                          type="text"
                          value={element.duration || ""}
                          onChange={(e) => handleChange(index, e)}
                          className="form-control"
                          name="duration"
                          placeholder="Duration"
                        />
                      </div>
                    </div>
                    {index ? (
                      <div className="col-auto my-1">
                        <button
                          type="button"
                          onClick={() => removeFormFields(index)}
                          className="btn btn-danger package-button btn-sm"
                        >
                          x
                        </button>
                      </div>
                    ) : (
                      <div className="col-auto my-1">
                        <button
                          type="button"
                          onClick={() => addFormFields(index)}
                          className="btn package-button add-button btn-sm"
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card card-light">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Service Description</label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-3">
        <div className="row">
          <div className="card card-light col-md-12">
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="inputEmail4">Action</label>
                <hr />
                {loading ? (
                  <div>Publishing...</div>
                ) : (
                  <button
                    className="btn add-button float-right"
                    onClick={() => handlePublish()}
                  >
                    Publish
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="card card-light col-md-12">
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="inputEmail4">Featured Image</label>
                <hr />
                {featuredImage.length > 0 ? (
                  <>
                    {" "}
                    <img
                      className="img-fluid"
                      src={featuredImage[0].secure_url}
                      alt=""
                    />
                    <p
                      type="button"
                      className="float-left set-service-image"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      <u>Change image</u>
                    </p>
                    <p
                      type="button"
                      className="float-right text-danger"
                      onClick={() => {
                        handleRemoveFeaturedImage();
                      }}
                    >
                      <u>Remove Image</u>
                    </p>
                  </>
                ) : (
                  <p
                    type="button"
                    className="float-left set-service-image"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    <u>Set featured image</u>
                  </p>
                )}

                <pImage.Provider
                  value={{ featuredImageId, setFeaturedImageId }}
                >
                  <FeaturedMediaGallery name="Service Image" />
                </pImage.Provider>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card card-light col-md-12">
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="inputEmail4">Image Gallery</label>
                <hr />
                {galleryLoading ? (
                  <div className="text-center py-5">
                    Loading images...
                  </div>
                ) : (
                  <div className="row">
                    {galleryImages.length > 0 &&
                      galleryImages.map((images, index) => (
                        <div
                          key={images._id}
                          className="col-md-4 px-1 pb-2 whole-div"
                        >
                          <button
                            className="cross"
                            onClick={() =>
                              removeGalleryImage(images._id, index)
                            }
                          >
                            x
                          </button>
                          <img
                            className="img-fluid image-div"
                            src={images.secure_url}
                            alt=""
                          />
                        </div>
                      ))}
                  </div>
                )}
                <div className="row mt-2">
                  <p
                    type="button"
                    className="float-left set-service-image"
                    data-toggle="modal"
                    data-target="#exampleModalCenter_2"
                  >
                    <u>Add image to gallery</u>
                  </p>
                  <gImage.Provider value={{ gImageId, setgImageId }}>
                    <MediaGallery name="Media Gallery" />
                  </gImage.Provider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default AddNewService;
export { pImage, gImage };
