import { ReactComponent as LogoDark } from "../assets/images/logos/hypeX-logo-dark.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  const role = localStorage.getItem('role')


  return (
    <>
    {
      role === "client"?
      <Link to="/client-auth/dashboard">
      <LogoDark style={{height: "62px", width: "200px"}} />
    </Link>
      :
      <Link to="/auth/dashboard">
      <LogoDark style={{height: "62px", width: "200px"}} />
    </Link>

    }
    </>
  );
};

export default Logo;
