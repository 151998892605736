import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { CONFIGURE_API, DEPARTMENT_API } from "../../constants/api";

function EditInclude() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [time, setTime] = useState("");
  const navigate = useNavigate()


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSingleInclude = async () => {
      const response = await fetch(
        CONFIGURE_API + "/includes/singleInclude/" + id
      );
      const json = await response.json();

      if (response.ok) {
        setTitle(json.data.title);
        setDescription(json.data.description);
        setStatus(json.data.status);
        setTime(json.data.time);

      }
    };

    fetchSingleInclude();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const loadingToastId = toast.loading("Updating Service...");
    const include = { title, description, time, status};

    try {
      const response = await fetch(CONFIGURE_API + `/includes/update/${id}`, {
        method: "PUT",
        body: JSON.stringify(include),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.error);
      } else {
        toast.dismiss(loadingToastId);
        navigate("/auth/allServices")
        toast.success("service updated successfully!");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("Error updating service:", error);
      toast.error("An error occurred while updating service.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h2 className="mb-5">Edit Service</h2>

      <div className="header-body">
        <div className="card">
          <div className="card-body">
            <form
              onSubmit={handleSubmit}
              className="row g-3 needs-validation"
              novalidate
            >
  
              <div className="col-md-6">
                <label htmlFor="inputCity" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control"
                  id="inputCity"
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputState" className="form-label">
                  Time
                </label>
                <input
                  type="number"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="form-control"
                  id="inputCity"
                  required
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="inputZip" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputZip"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            
              <div className="col-md-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={status}
                    id="flexCheckIndeterminate"
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckIndeterminate"
                  >
                    Active
                  </label>
                </div>
              </div>

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-dark float-right"
                  style={{ color: "white", background: "black" }}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditInclude;
