
import React from "react";

import "./SectionTwoMobile.css";


function SectionTwoMobile() {

  return (
    <div className="section-two-mobile d-flex">
      <div className="container align-self-center">
      
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center section-two-heading hype-blue">
              UNLIMITED CONTENT
            </h1>
            <h1 className="text-center section-two-heading hype-gray">
              AT YOUR <span className="hype-red">FINGERTIPS</span> 
            </h1>
          
          </div>
        </div>
       
      
      </div>
    </div>
  );
}

export default SectionTwoMobile;
