import React from "react";
import { useEffect, useState, useContext } from "react";
import "./SectionFour.css";

import { NavContext, ThreeContext, ThreeOneContext } from "../../LandingPage";
const touchThreshold = 150;
function SectionFour() {
  const { index, setIndex } = useContext(NavContext);
  const {threeAnimation, setThreeAnimation} = useContext(ThreeContext)
  const {threeOneAnimation, setThreeOneAnimation} = useContext(ThreeOneContext)
  let touchStartY = 0;

  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if (event.deltaY > 0) {
        setIndex(7);
      } else {
        setThreeAnimation(1)
        setThreeOneAnimation(1)
        setIndex(5); 
      }
    }
  };

  const handleTouchStart = (event) => {
    const target = event.currentTarget;
    const startY = event.touches[0].clientY;
    //  alert(startY)
    touchStartY = startY;
  };

  const handleTouchMove = async (event) => {
    let x = touchStartY;
    if (touchStartY !== 0) {
      const deltaY = event.touches[0].clientY - touchStartY;
      if (Math.abs(deltaY) > touchThreshold) {
        const direction = deltaY > 0 ? 1 : -1;
        if (direction === 1) {
          setIndex(3);
        } else {
          setIndex(7);
        }
        touchStartY = 0;
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartY = 0;
  };

  useEffect(() => {
    const sectionOneElement = document.getElementById("section-four");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
      sectionOneElement.addEventListener("touchstart", handleTouchStart);
      sectionOneElement.addEventListener("touchmove", handleTouchMove);
      sectionOneElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      console.log(index);
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
        sectionOneElement.removeEventListener("touchstart", handleTouchStart);
        sectionOneElement.removeEventListener("touchmove", handleTouchMove);
        sectionOneElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [index]);
  return (
    <div id="section-four" className="section-four d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center come-in-animation-class">
          <div className="col-md-10">
            <h1 className="text-center fs-1 fw-bold section-four-heading">
              We've changed the way content creation <br /> works for brands.
            </h1>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center hide-show-animation-class">
          <div className="col-md-10 ">
            <div className="row">
              <div className="col-md-4 sec-four-cols">
                <div className="card landing-page-card border-0">
                  <img
                    src="/media/section-four-star.svg"
                    className="card-img-top sec-four-image"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h2 className="card-title fs-2 fw-bold sec-four-card-title">
                      subscribe
                    </h2>
                    <p className="card-text sec-four-card-text">
                      Unlimited content created by a team of designers,
                      storytellers and content creators.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 sec-four-cols">
                <div className="card landing-page-card border-0">
                  <img
                    src="/media/section-four-request.svg"
                    className="card-img-top sec-four-image"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h2 className="card-title fs-2 fw-bold sec-four-card-title">
                      request
                    </h2>
                    <p className="card-text sec-four-card-text">
                      Make a request & get your content in{" "}
                      <span className="fw-bold">48 hours</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 sec-four-cols">
                <div className="card landing-page-card border-0">
                  <img
                    src="/media/section-four-review.svg"
                    className="card-img-top sec-four-image"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h2 className="card-title fs-2 fw-bold sec-four-card-title">
                      review
                    </h2>
                    <p className="card-text sec-four-card-text">
                      Get your content reviewed and revised as many times as you
                      want.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFour;
