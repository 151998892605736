import React, { useState, useEffect, useContext } from "react";
import "./Brief.css";
import { toast } from "react-toastify";
import Select from "react-select"
import {
  
  BRIEF_API,
  CLIENT_API,
  CONFIGURE_API,
  USER_API,
 
} from "../../../constants/api";
import { UpdateNotification } from "../../../layouts/FullLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "reactstrap";

function Brief() {
  const {id} = useParams()
  const myId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const {update, setUpdate} = useContext(UpdateNotification)
  const [projectPackage, setProjectPackage] = useState("")
  const [projectCategory, setProjectCategory] = useState("")
  const [userEmail, setUserEmail] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [price, setPrice] = useState("");
  const [packageId, setPackageId] = useState("")
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [services, setServices] = useState([])


  const fetchPackageDetails = async (p_id) => {
    try{

      const response = await fetch(`${CONFIGURE_API}/includes/list/${p_id}`)
      const json = await response.json()
      if(response.ok){

   
        const options = json.data.map((service)=>(
          {
            value: service._id,
            label: service.title
          }
        ))

        setServices(options)
      }

    }catch(error){
      toast.error(error);
    }
  }
  useEffect(() => {
  


    const fetchProjectDetails = async () => {
      try{
  
        const response = await fetch(`${CLIENT_API}/single/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      
        )
        const json = await response.json()
        if(response.ok){
          setProjectCategory(json.data.projectCategory.name)
          setProjectPackage(json.data.packageName)
          fetchPackageDetails(json.data.package.id)
        }
  
      }catch(error){
        toast.error(error);
      }
    }
  
    fetchProjectDetails()
 
  }, [id]);

  const handleImageChange = (e) => {
    setFile(e.target.files[0]);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Submitting Project Details...");

    const formData = new FormData();

    formData.append("createdBy", myId);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("clientProjectId", id);

    if (file) {
      formData.append("file", file);
    }
    try {
      const response = await fetch(BRIEF_API + "/create", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        navigate(`/client-auth/client-order-page/${id}`)
        toast.success("Brief submitted successfully!");
        setUpdate(Math.random())

      }
    } catch (error) {
      console.error("Error submitting Brief:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while submitting.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container-fluid">
      <h2 className="mb-3">Create New Brief</h2>
      <h5 className="mb-5">
        {projectCategory}  {projectPackage === "Essential" || projectPackage === "E-commerce" ? (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #0f64a2)" }}
              className="ms-1"
            >
              {projectPackage}
            </Badge>
          ) : (
            <Badge
              style={{ background: "linear-gradient(to right, #1f2123, #d09c17)" }}
              className="ms-2"
            >
              {projectPackage}
            </Badge>
          )}
      </h5>
      <div className="header-body">
        <div className="card">
          <div className="card-body">
            <form className="row g-3">
             
              <div className="col-md-8">
              <label htmlFor="inputEmail4" className="form-label">
                Choose Service
              </label>
             <Select
             options={services}
             onChange={(e)=>setTitle(e.value)}
           
             />
              </div>
              <div className="col-md-4">
                <div>
                  <label htmlFor="formFile" className="form-label">
                    Attach File (Optional)
                  </label>
                  <input
                    onChange={handleImageChange}
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept="image/*,.pdf"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor="inputEmail4" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                  id="exampleFormControlTextarea1"
                  rows={8}
                
                />
              </div>

              <div className="col-12">
                <button
                onClick={handleSubmit}
                  type="submit"
                  className="btn btn-dark float-right"
                  style={{ color: "white", background: "black" }}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brief;
