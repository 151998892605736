import React, { useState, useEffect, useContext } from "react";
import "./SectionSeven.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FAQ_API } from "../../../constants/api";
import { NavContext } from "../../LandingPage";
const touchThreshold = 150;
function SectionSeven() {
  const { index, setIndex } = useContext(NavContext);
  const [expanded, setExpanded] = useState("panel-1");
  const [faqs, setFaqs] = useState(null);

  let touchStartY = 0;

  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if (event.deltaY > 0) {
        setIndex(10);
      } else {
        setIndex(8);
      }
    } 
  };
 
  const handleTouchStart = (event) => {
    const target = event.currentTarget;
    const startY = event.touches[0].clientY;
    //  alert(startY)
    touchStartY = startY;
  };

  const handleTouchMove = async (event) => {
    let x = touchStartY;
    if (touchStartY !== 0) {
      const deltaY = event.touches[0].clientY - touchStartY;
      if (Math.abs(deltaY) > touchThreshold) {
        const direction = deltaY > 0 ? 1 : -1;
        if (direction === 1) {
          setIndex(8);
        } else {
          setIndex(10);
        }
        touchStartY = 0;
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartY = 0;
  };

  useEffect(() => {
    const sectionOneElement = document.getElementById("section-seven");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
      sectionOneElement.addEventListener("touchstart", handleTouchStart);
      sectionOneElement.addEventListener("touchmove", handleTouchMove);
      sectionOneElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      console.log("removed 3");
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
        sectionOneElement.removeEventListener("touchstart", handleTouchStart);
        sectionOneElement.removeEventListener("touchmove", handleTouchMove);
        sectionOneElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [index]);
  useEffect(() => {
    const getFaqs = async () => {
      const response = await fetch(FAQ_API + "/list");
      const faq = await response.json();

      if (response.ok) {
        setFaqs(faq.data);
        setExpanded(faq.data[faq.data.length - 5]._id);
      }
    };

    getFaqs();
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleBookCall = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div id="section-seven" className="section-seven d-flex">
      <div className="container align-self-center">
        <div className="row d-flex justify-content-center come-in-animation-class">
          <div className="col-md-10">
            <h1 className="text-center fs-1 fw-bold section-seven-heading">
              We Got You Covered
            </h1>
          </div>
        </div>

        <div className="row mt-4 d-flex justify-content-center hide-show-animation-class">
          <div className="col-md-10">
            <div className="row">
              <div className="col-lg-6 sec-seven-col p-2 ">
                <div className="sec-seven-card-1 p-4 d-flex flex-wrap overflow-auto align-items-center">
                  <div className="container-fluid">
                    {faqs &&
                      faqs.slice(faqs.length - 5, faqs.length).map((faq) => (
                        <Accordion
                          key={faq._id}
                          expanded={expanded === faq._id}
                          onChange={handleChange(faq._id)}
                          className="section-seven-accordion"
                        >
                          <AccordionSummary
                            className="section-seven-accordion-summary"
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography className="section-seven-accordion-question fw-bold">
                              {faq.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="section-seven-accordion-answer">
                              {faq.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sec-seven-col p-2 d-none d-lg-flex d-xl-flex align-items-center">
                <div className="sec-seven-card-2 shadow p-4 container-fluid d-flex flex-column gap-2 justify-content-center">
                  <div className="row">
                    <div className="col-12 sec-seven-card-2-col-1 d-flex align-items-center justify-content-end">
                      <img
                        src="/media/section-six-phone.svg"
                        className="section-seven-image"
                        alt=""
                      />
                    </div>
                    <div className="col-12 sec-seven-card-2-col-2 d-flex align-items-end">
                      <h1 className="section-seven-cta-heading text-white">
                        Book a <br /> Call
                      </h1>
                    </div>
                    <div className="col-12 sec-seven-card-2-col-3 d-flex align-items-center">
                      <button
                        onClick={() =>
                          handleBookCall(
                            "https://calendly.com/hypeappstore/30min"
                          )
                        }
                        className="btn section-seven-cta"
                      >
                        Book a call
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSeven;
