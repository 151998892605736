import React, { useState, useEffect } from "react";
import "./WorkPlace.css";
import { toast } from "react-toastify";
import { DEPARTMENT_API, TASK_API, TEAM_API } from "../../../constants/api";

function WorkPlace() {
  const id = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const [team, setTeam] = useState(null);
  const departmentID = localStorage.getItem("departmentId");
  const [departmentName, setDepartmentName] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [tasks, setTasks] = useState(null)
  const [currentId, setCurrentId] = useState("")
  const [update, setUpdate] = useState(0)

  useEffect(() => {
    const getDepartmentName = async () => {
      const response = await fetch(DEPARTMENT_API + "/" + departmentID);
      const json = await response.json();

      if (response.ok) {
        setDepartmentName(json.data.departName);
      }
    };
    const getTasks = async () => {
      const response = await fetch(TASK_API + "/listByLead/" + id);
      const json = await response.json();

      if (response.ok) {
        setTasks(json.data);
      }
    };
    const fetchTeam = async () => {
      const response = await fetch(TEAM_API + "/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        if (json.data == null) {
        } else {
          const filtered = json.data.filter((data) => data.isDeleted === false);
          if (filtered) {
            console.log(filtered);
            setTeam(filtered);
          } else {
          }
        }
      } else {
      }
    };

    getTasks()
    fetchTeam();

    getDepartmentName();
  }, [departmentID, update]);

  const handleSubmit = async (e) => {

    const createdBy = id
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Creating New Task...");
    const user = {
      title,
      description,
      assignedTo,
      createdBy,
    };
    console.log("check: " + user);
    try {
      const response = await fetch(TASK_API + "/create", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("Task Created successfully!");
        setTitle("");
        setDescription("");
        setAssignedTo("");
        setUpdate(Math.random())
       
      }
    } catch (error) {
      console.error("Error creating tast:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while creating task.");
    } finally {
      setLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

    const handleDrag = (key) => {
      setCurrentId(key)
      // alert(key)
    };
 
    const setStatus =  async (status) => {
      
    
      
      const response = await fetch(TASK_API+"/updateStatus/"+currentId, {
        method: "PUT",
        body: JSON.stringify({status}),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if(response.ok){
        setUpdate(Math.random())
      }
      
    }

  const cardDropped = (e) => {
    // alert("card-dropped in " + e.target.id);

    if(e.target.id === "to-do"){
      setStatus("to-do")
    }
    if(e.target.id === "backlock"){
      setStatus("pending")
    }
    if(e.target.id === "in-progress"){
      setStatus("in-progress")
    }
    if(e.target.id === "done"){
      setStatus("completed")
    }
    

  };
  return (
    <div className="container-fluid">
      <div className="row p-4">
        <div className="col-md-6">
          <h2 className="lead-workplace-title">
            Workplace {departmentName} Team
          </h2>
        </div>
        <div className="col-md-6 text-right">
          <button
            className="add-task-button"
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
          >
            {" "}
            <img src="/task.svg" alt="" />
            &nbsp; Add a Task
          </button>
          <div
            className="modal fade bd-example-modal-lg"
            tabIndex={-1}
            role="dialog"
            data-backdrop="static"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content py-2">
                <div className="container-fluid">
                  <div className="row">
                    {/* left column */}
                    <div className="col-md-12">
                      {/* general form elements */}
                      <div className="card card-light">
                        <div className="card-header">
                          <h3 className="card-title">Add New Task</h3>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        {/* /.card-header */}
                        {/* form start */}
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-row">
                              <div className="form-group col-md-12 text-left">
                                <label htmlFor="inputEmail4">Title</label>
                                <input
                                  type="text"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  placeholder="design login page"
                                  className="form-control"
                                  id="inputEmail4"
                                />
                              </div>
                            </div>
                            <div className="form-group text-left">
                              <label htmlFor="inputAddress">Description</label>
                              <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                className="form-control"
                                placeholder="design login page for both client and admin"
                                id="inputAddress"
                                rows="10"
                              />
                            </div>
                            <div className="form-group col-md-12 text-left">
                              <label htmlFor="inputState">Assigned To</label>
                              <select
                                onChange={(e) => setAssignedTo(e.target.value)}
                                id="inputState"
                                className="form-control"
                              >
                                <option value="" disabled selected>
                                  Select Team Member
                                </option>
                                {team &&
                                  team.map((teams) => (
                                    <option key={teams._id} value={teams._id}>
                                      {teams.firstname}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          {/* /.card-body */}
                          <div className="card-footer d-flex justify-content-end">
                            {loading ? (
                              <div>Loading...</div>
                            ) : (
                              <button
                                type="submit"
                                className="btn add-new-button"
                              >
                                Create New Task
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="lead-workplace">
          <div
            id="backlock"
            className="workplace-task-grid shadow"
            onDragOver={handleDragOver}
            onDrop={cardDropped}
          >
            <h2 className="workplace-task-grid-title">Backlog</h2>
            {
              tasks && tasks.filter(task => task.status === "pending").map((task)=>(
                <div key={task._id} className="workplace-task-grid-card urgent d-flex align-items-center pl-3" draggable onDrag={()=>handleDrag(task._id)}>
                  <p className="text-white">{task.title}</p>
                </div>
              ))
            }
          </div>
          <div
            className="workplace-task-grid shadow"
            id="to-do"
            onDragOver={handleDragOver}
            onDrop={cardDropped}
          >
            <h2 className="workplace-task-grid-title">To do</h2>
            {
              tasks && tasks.filter(task => task.status === "to-do").map((task)=>(
                <div key={task._id} className="workplace-task-grid-card stuck d-flex align-items-center pl-3" draggable onDrag={()=>handleDrag(task._id)}>
                  <p className="text-white">{task.title}</p>
                </div>
              ))
            }
          </div>
          <div
            className="workplace-task-grid shadow"
            id="in-progress"
            onDragOver={handleDragOver}
            onDrop={cardDropped}
          >
            <h2 className="workplace-task-grid-title">In progress</h2>
            {
              tasks && tasks.filter(task => task.status === "in-progress").map((task)=>(
                <div key={task._id} className="workplace-task-grid-card in-process d-flex align-items-center pl-3" draggable onDrag={()=>handleDrag(task._id)}>
                  <p className="text-white">{task.title}</p>
                </div>
              ))
            }
          </div>
          <div
            className="workplace-task-grid shadow"
            id="done"
            onDragOver={handleDragOver}
            onDrop={cardDropped}
          >
            <h2 className="workplace-task-grid-title">Done</h2>
            {
              tasks && tasks.filter(task => task.status === "completed").map((task)=>(
                <div key={task._id} className="workplace-task-grid-card done d-flex align-items-center pl-3" draggable onDrag={()=>handleDrag(task._id)}>
                  <p className="text-white">{task.title}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="row p-4">
        <div className="guide-color-container">
          <div className="d-flex">
            <div className="guide-color-1"></div>
            <span className="guide-name ml-2">Urgent</span>
          </div>
          <div className="d-flex ml-3">
            <div className="guide-color-2"></div>
            <span className="guide-name ml-2">In Process</span>
          </div>
          <div className="d-flex ml-3">
            <div className="guide-color-3"></div>
            <span className="guide-name ml-2">Stuck</span>
          </div>
          <div className="d-flex ml-3">
            <div className="guide-color-4"></div>
            <span className="guide-name ml-2">Done</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkPlace;
