import React, { useEffect, useState } from "react";
import "./ClientLogin.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { USER_API } from "../constants/api";

function ClientLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const check_token = localStorage.getItem("token");

    if (check_token) {
      navigate("/client-auth/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Logging in...");
    const user = { email, password };

    try {
      const response = await fetch(USER_API + "/login", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);

        if (json.user.role === "client") {
          toast.success("Logged In successfully!");
          localStorage.setItem("token", json.token);
          localStorage.setItem("userId", json.user.id);
          localStorage.setItem("role", json.user.role);
          navigate("/client-auth/dashboard");
        } else {
          toast.dismiss(loadingToastId);
          toast.error("User does Not exist.");
        }
      }
    } catch (error) {
      console.error("Error login admin:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while Login admin.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="client-login-page d-flex flex-column">
      <div className="align-self-start mx-auto mt-5">
        <img
          src="/media/hypeX-logo-dark.svg"
          role="button"
          onClick={handleClick}
          className="img-fluid"
          alt="profile"
        />
      </div>

      <div className="container align-self-center my-auto">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h2 className="text-center text-dark">HypeX CRM</h2>
            <div className="text-center text-dark">Login to Dashboard</div>
            <div className="my-5">
              <form
                className="card-body cardbody-color p-lg-5"
                style={{ backgroundColor: "transparent" }}
              >
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    id="Username"
                    aria-describedby="emailHelp"
                    placeholder="User Name"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    placeholder="password"
                  />
                </div>
                <div className="text-center">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn client-login-btn-color px-5 mb-1 w-100"
                  >
                    Login
                  </button>
                </div>
                <div className="text-center">
                  <Link to="/" className="px-5 mb-5 w-100 text-dark">
                    Back to Home
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientLogin;
