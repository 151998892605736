import React, { useState, useEffect, useContext } from "react";

import SectionOne from "./sectionOne/SectionOne";
import SectionTwo from "./sectionTwo/SectionTwo";
import SectionThree from "./sectionThree/SectionThree";
import SectionFour from "./sectionFour/SectionFour";
import SectionFive from "./sectionFive/SectionFive";
import SectionSix from "./sectionSix/SectionSix";
import SectionSeven from "./sectionSeven/SectionSeven";
import SectionEight from "./sectionEight/SectionEight";

import './SectionOneComponents.css';
import { NavContext } from "../LandingPage";
import Reel from "./sectionTwo/Reel.jsx/Reel";
import SectionThreeOne from "./sectionThree/SectionThreeOne";
import SectionThreeTwo from "./sectionThree/SectionThreeTwo";

function SectionOneComponent() {
    const { index, setIndex } = useContext(NavContext);
  

    return (
        <div>
            <div className={`${index === 0 ? "d-block" : "d-none"}`}>
                <SectionOne />
            </div>
            <div className={`${index === 1 ? "d-block" : "d-none"}`}>
                <SectionTwo />
            </div>
            <div className={`${index === 2 ? "d-block" : "d-none"}`}>
                <Reel />
            </div>
            <div className={`${index === 3 ? "d-block" : "d-none"}`}>
                <SectionThree />
            </div>
            <div className={`${index === 4 ? "d-block" : "d-none"}`}>
                <SectionThreeOne />
            </div>
            <div className={`${index === 5 ? "d-block" : "d-none"}`}>
                <SectionThreeTwo />
            </div>
           
            <div className={`${index === 6 ? "d-block" : "d-none"}`}>
                <SectionFour />
            </div>
            <div className={`${index === 7 ? "d-block" : "d-none"}`}>
                <SectionFive />
            </div>
            <div className={`${index === 8 ? "d-block" : "d-none"}`}>
                <SectionSix />
            </div>
            <div className={`${index === 9 ? "d-block" : "d-none"}`}>
                <SectionSeven />
            </div>
            <div className={`${index === 10 ? "d-block" : "d-none"}`}>
                <SectionEight />
            </div>
        </div>
    );
}

export default SectionOneComponent;
