import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AddNewUser.css";
import { toast } from "react-toastify";
import { DEPARTMENT_API, USER_API } from "../../../constants/api";

function AddNewUser() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [loading, setLoading] = useState(false);
  const [allDepartments, setAllDepartments] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const getAllDepartments = async () => {
      const response = await fetch(DEPARTMENT_API + "/list");
      const all_departments = await response.json();
      if (response.ok) {
        setAllDepartments(all_departments.data);
      }
    };

    getAllDepartments();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Creating New User...");
    const user = {
      username,
      email,
      password,
      firstname,
      lastname,
      website,
      role,
      department,
    };

    console.log("check: " + user);

    try {
      const response = await fetch(USER_API + "/register", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      }
      if (response.ok) {
        toast.dismiss(loadingToastId);
        toast.success("User Created successfully!");
        setUsername("");
        setPassword("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setWebsite("");
        setRole("");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while creating user.");
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="container-fluid">
      <h2 className="mb-5">Add New User</h2>

      <div className="header-body">
        <div className="card">
          <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
             
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4">Username</label>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="@sami5"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputPassword4">Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="create password"
                      className="form-control"
                      id="inputPassword4"
                    />
                  </div>
             
                <div className="col-md-12">
                  <label htmlFor="inputAddress">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="samwinch@gmail.com"
                    id="inputAddress"
                  />
                </div>

              
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4">First Name</label>
                    <input
                      type="text"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Sam"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputPassword4">Last Name</label>
                    <input
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Winch"
                      className="form-control"
                      id="inputPassword4"
                    />
                  </div>
             

             
                  <div className="col-md-4">
                    <label htmlFor="inputCity">Website</label>
                    <input
                      type="text"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      className="form-control"
                      id="inputCity"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputState">Department</label>
                    <select
                      onChange={(e) => setDepartment(e.target.value)}
                      id="inputState"
                      className="form-select"
                    >
                        <option value="" disabled selected>Select Department</option>
                      {allDepartments &&
                        allDepartments.map((singleDepartment) => (
                          <option
                            key={singleDepartment._id}
                            value={singleDepartment._id}
                          >
                            {singleDepartment.departName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputState">Role</label>
                    <select
                      onChange={(e) => setRole(e.target.value)}
                      id="inputState"
                      className="form-select"
                    >
                      <option value="" disabled selected>Select Role</option>
                      <option value="lead">Lead</option>
                      <option value="employee">Employee</option>                  
                      <option value="admin">Admin</option>
                    </select>
                  </div>
            
             
              {/* /.card-body */}
              <div className="col-md-12">
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <button type="submit" className="btn float-right"
                  style={{ color: "white", background: "black" }}
                  >
                    Create User
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUser;
