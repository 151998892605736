import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CATEGORY_API, CONFIGURE_API, COUNTRY_API } from "../../constants/api";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
function AddNewPackage() {
  const [name, setName] = useState("");
  const [type, setType] = useState("Essential");
  const [services, setServices] = useState([]);
  const [briefs, setBriefs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [category, setCategory] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [stripe, setStripe] = useState("");
  const [price, setPrice] = useState("");
  const [requests, setRequests] = useState("")
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchIncludes = async () => {
      const response = await fetch(CONFIGURE_API + "/includes/list");
      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((brief) => ({
          value: brief._id,
          label: brief.title,
        }));
        setBriefs(options);
      }
    };
    const fetchCategories = async () => {
      const response = await fetch(CATEGORY_API + "/list");
      const json = await response.json();

      if (response.ok) {
        setCategories(json.data);
      }
    };
    const fetchCountries = async () => {
      const response = await fetch(COUNTRY_API + "/list");
      const json = await response.json();

      if (response.ok) {
        const options = json.data.map((country)=>({
          value: country._id,
          label: country.name
        }));
        setCountries(options)
      }
    };

    fetchIncludes();

    fetchCategories();
    fetchCountries();
  }, []);
  const handleServiceChange = (selectedOptions) => {
    setServices(selectedOptions ? selectedOptions.map((opt) => opt.value) : []);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loadingToastId = toast.loading("Submitting Package Details...");

    const packages = {
      name,
      type,
      description,
      services,
      stripe,
      status,
      price,
      category,
      country,
      requests
    };

    try {
      const response = await fetch(CONFIGURE_API + "/packages/create", {
        method: "POST",
        body: JSON.stringify(packages),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await response.json();

      if (!response.ok) {
        toast.dismiss(loadingToastId);
        toast.error(json.message);
      } else {
        toast.dismiss(loadingToastId);
        navigate("/auth/allPackages")
        toast.success("Package added successfully!");
      }
    } catch (error) {
      console.error("Error creating Service:", error);
      toast.dismiss(loadingToastId);
      toast.error("An error occurred while creating.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h2 className="mb-5">Create New Package</h2>

      <div className="header-body">
        <div className="card">
          <div className="card-body">
            <form
              onSubmit={handleSubmit}
              className="row g-3 needs-validation"
              noValidate
            >
              <div className="col-md-4">
                <label htmlFor="inputCity" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  id="inputCity"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputState" className="form-label">
                  Category
                </label>
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  id="inputState"
                  className="form-select"
                >
                  <option value="" disabled selected>
                    Choose...
                  </option>
                  {categories &&
                    categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputState" className="form-label">
                  Type
                </label>

                <select
                  onChange={(e) => setType(e.target.value)}
                  id="inputState"
                  className="form-select"
                  value={type}
                >
                  <option value="Essential">Essential</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>

              <div className="col-md-12">
                <label htmlFor="inputZip" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputZip"
                  rows={5}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-md-8">
                <label htmlFor="inputState" className="form-label">
                  Services
                </label>
                <Select
                  isMulti
                  onChange={handleServiceChange}
                  options={briefs}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputCity" className="form-label">
                  Requests at a Time
                </label>
                <input
                  type="number"
                  onChange={(e) => setRequests(e.target.value)}
                  className="form-control"
                  id="inputCity"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputCity" className="form-label">
                  Stripe URL
                </label>
                <input
                  type="text"
                  onChange={(e) => setStripe(e.target.value)}
                  className="form-control"
                  id="inputCity"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputCity" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                  className="form-control"
                  id="inputCity"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputState" className="form-label">
                  Country
                </label>
               <Select
               options={countries}
               onChange={(e)=>setCountry(e.value)}
               />
              </div>
              <div className="col-md-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={status}
                    id="flexCheckIndeterminate"
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckIndeterminate"
                  >
                    Active
                  </label>
                </div>
              </div>

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-dark float-right"
                  style={{ color: "white", background: "black" }}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewPackage;
